<script>
import { debounce } from 'lodash-es'
import logUser from '@/mixins/login.js'

export default {
  name: 'connect',
  mixins: [logUser],
  data () {
    return {
      username: null,
      password: null,
      showPass: true,
      error: false,
      seed: 'dokoma',
      backupOpt: {
        looping: false,
        couleurDebut: '#ff284f',
        couleurFin: '#ff6581',
        nbPoint: 3,
        complexite: 20,
        contraste: 80,
        angularite: 100,
        duree: 7,
        transition: 'elastic',
        largeur: 1,
        radius: 100
      },
      blobData: {
        looping: false,
        couleurDebut: '#ff284f',
        couleurFin: '#ff6581',
        nbPoint: 3,
        complexite: 20,
        contraste: 80,
        angularite: 100,
        duree: 7,
        transition: 'elastic',
        largeur: 1,
        radius: 100
      },
      logging: false
    }
  },
  methods: {
    errorBlob () {
      this.blobData = {
        contour: false,
        largeur: 0.5,
        nbPoint: 10,
        complexite: 100,
        contraste: 100,
        radius: 200,
        angularite: 0,
        couleurDebut: '#FF5252',
        couleurFin: '#ff2e2e',
        duree: 0.5,
        transition: 'glitch',
        seed: 'error'
      }
    },
    majBlob: debounce(function (type) {
      if (this.error) {
        this.error = false
        this.blobData = this.backupOpt
      }
      if (type === 'u') {
        this.seed = this.username
        if (this.username && this.username.length > 6 && this.username.length < 60) {
          this.blobData.nbPoint = this.username.length / 2
        } else {
          this.blobData.nbPoint = 3
        }
      } else {
        if (this.password) {
          this.blobData.radius = 100 + (this.password.length * 20)
        } else {
          this.blobData.radius = 100
        }
      }
    }, 500, { trailing: true }),
    async login () {
      this.logging = true
      await this.loginUserV2(this.username, this.password, 'connect')
      this.logging = false
    }
  }
}
</script>
<template lang="pug">
  .connexion-index
    div.login-blob-wrapper(id='loginBlob')
      dokoma-blob(:rotating='true', :seed='seed', :idle='true', :blobOptions='blobData', :debugOptions='false')
    //- img.tc.mt2.relative(src='~@/assets/images/logos/dokoma-black.svg', width='100')
    div.flex-grow-1

    div.connexion-introduction.relative
      h1.login-title Espace <br> parent
    div.flex-grow-1

    div.connexion-informations.relative
      v-alert.animated.fadeInUp(elevation='5', border='left', colored-border, v-model='error', type='error', dense, rounded, style='font-size:13px') Veuillez vérifier vos informations de connexion
      v-form(id='parentConnect')
        div.mb1.f6 Identifiant
        v-text-field(v-model='username', @input='majBlob("u")', color='secondary', rounded, solo, hide-details, style='font-size: 22px')
        div.mb1.mt2.f6 Mot de passe
        v-text-field#passwordField(v-model='password', @input='majBlob("p")', :type='showPass ? "password" : "text"', autocomplete="current-password", @keydown.enter='login', color='secondary', maxlength='4', rounded, solo, hide-details, style='font-size: 22px')
          template(#append)
            v-btn(@click='showPass = !showPass', icon)
              font-awesome-icon(:icon="['fad', showPass ? 'eye-slash' : 'eye']")
      v-btn.mt3(@click='login', block, :disabled='(!username || !password) || logging', rounded, color='dark', dark, large, style='font-size:20px;') {{ logging ? 'Connexion en cours...' : 'Connexion' }}
      div.tc.mt2(style='line-height:1')
        a.f6.tc.b.black--text(href='https://www.laclassealamaison.com/creationCompte', target='_blank') Inscription
    div.flex-grow-1
</template>
<style lang='sass'>
  @import 'src/styles/pages/_connexion'

  .login-blob-wrapper
    position: fixed
    top: calc(50% - 30vh)
    left: 0
    // bottom: 0
    width: 100vw
    height: 60vh
    display: flex
    justify-content: center
    align-items: center
    transition: all 0.25s ease-in
    .blob-wrapper
      // position: fixed
      // top: 0
      // left: 0
      height: 100%
      width: 100%
      #blob
        // max-width: 1000px
        width: 100%
        margin: 0 auto
        height: 0
        padding-top: 98%
        position: relative
        svg#svblob
          position: absolute
          top: 0
          left: 0
          height: 60vh !important
          width: 100vw !important

  // Options pour le package blob
  #svblob
    overflow: visible !important
  .rotation-animation
    animation: rotation 120s infinite linear
    -webkit-animation: rotation 120s infinite linear
  @-webkit-keyframes rotation
    from
      -webkit-transform: rotate(0deg)
    to
      -webkit-transform: rotate(359deg)
  .options-wrapper
    position: absolute
    background-color: rgba(255,255,255,0.5)
    padding: 20px
    margin: 20px
    border-radius: 10px
    top: 0
    right: 0
    z-index: 10
    width: 400px
    overflow-x: hidden
    font-size: 10px
    .param
      display: flex
      justify-content: space-between
      margin-bottom: 5px
</style>
